import {
  GET_ACCESS_SUCCESS_TYPE,
  GET_ACCESS_FAIL_TYPE,
  GET_CREATE_ACCESS_SUCCESS_TYPE,
  GET_CREATE_ACCESS_FAIL_TYPE,
  GET_UPDATE_ACCESS_SUCCESS_TYPE,
  GET_UPDATE_ACCESS_FAIL_TYPE,
  GET_DELETE_ACCESS_SUCCESS_TYPE,
  GET_DELETE_ACCESS_FAIL_TYPE,
  GET_ACCESS_SUCCESS_TYPE1,
  GET_ACCESS_FAIL_TYPE1,
} from "../services/Common";

const initialState = { data: [], isLoading: false, status: null, message: '' };

export const accessReducer = (state, action) => {
  state = state || initialState;

  if (action.type === GET_ACCESS_SUCCESS_TYPE1) {
    return {
      data: action.data,
      isLoading: action.isLoading,
      status: action.status,
      message: action.message,
    };
  }

  if (action.type === GET_ACCESS_FAIL_TYPE1) {
    return {
      data: action.data,
      isLoading: action.isLoading,
      status: action.status,
      message: action.message,
    };
  }

  if (action.type === GET_ACCESS_SUCCESS_TYPE) {
    return {
      data: action.data,
      isLoading: action.isLoading,
      status: action.status,
      message: action.message,
    };
  }

  if (action.type === GET_ACCESS_FAIL_TYPE) {
    return {
      data: action.data,
      isLoading: action.isLoading,
      status: action.status,
      message: action.message,
    };
  }

  if (action.type === GET_CREATE_ACCESS_SUCCESS_TYPE) {
    return {
      data: action.data,
      isLoading: action.isLoading,
      status: action.status,
      message: action.message,
    };
  }

  if (action.type === GET_CREATE_ACCESS_FAIL_TYPE) {
    return {
      data: action.data,
      isLoading: action.isLoading,
      status: action.status,
      message: action.message,
    };
  }

  if (action.type === GET_UPDATE_ACCESS_SUCCESS_TYPE) {
    return {
      data: action.data,
      isLoading: action.isLoading,
      status: action.status,
      message: action.message,
    };
  }

  if (action.type === GET_UPDATE_ACCESS_FAIL_TYPE) {
    return {
      data: action.data,
      isLoading: action.isLoading,
      status: action.status,
      message: action.message,
    };
  }

  if (action.type === GET_DELETE_ACCESS_SUCCESS_TYPE) {
    return {
      data: action.data,
      isLoading: action.isLoading,
      status: action.status,
      message: action.message,
    };
  }

  if (action.type === GET_DELETE_ACCESS_FAIL_TYPE) {
    return {
      data: action.data,
      isLoading: action.isLoading,
      status: action.status,
      message: action.message,
    };
  }
  return state;
};