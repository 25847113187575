import System from './icons/menus/HeThong.png';
import Report from './icons/menus/BaoCao.png';
import IndexManagement from './icons/menus/QuanLyDanhMuc.png';
import CompanyManagement from './icons/menus/QuanLyDoanhNghiep.png';
import StampSeries from './icons/menus/QuanLyTemTruyXuat.png';
import Financial from './icons/menus/QuanLyThu.png';
import Website from './icons/menus/QuanLyWebSite.png';


import Report1 from './icons/menus/Report1.png';
import Report2 from './icons/menus/Report2.png';
import Report3 from './icons/menus/Report3.png';
import Report4 from './icons/menus/Report4.png';
import Report8 from './icons/menus/Report4.png';
import Report9 from './icons/menus/Report4.png';
import ReportMananger from './icons/menus/Report4.png';
import ReportView from './icons/menus/Report4.png';
import News from './icons/menus/TinTuc.png';
import Menus from './icons/menus/Menu.png';
import Banner from './icons/menus/QuangCao.png';
import Blogs from './icons/menus/BaiViet.png';
import WebConfig from './icons/menus/CauHinhWebsite.png';
import StampFees from './icons/menus/ThuTienTem.png';
import RegisteredFees from './icons/menus/ThuTienDangKy.png';
import StampRequests from './icons/menus/Tem.png';
import StampDetails from './icons/menus/Tem.png';
import Informations from './icons/menus/KeKhai.png';
import Access from './icons/menus/TruyXuat.png';

import Roles from './icons/menus/NhomQuyen.png';
import Permissions from './icons/menus/PhanQuyenChucNang.png';
import Zones from './icons/menus/VungDuLieu.png';
import RoleZones from './icons/menus/PhanQuyenDuLieu.png';
import Prices from './icons/menus/BangGia.png';
import Logs from './icons/menus/NhatKyHeThong.png';
import ConfirmedCompanies from './icons/menus/DanhSach.png';
import FreshCompanies from './icons/menus/DanhSach.png';
import ConfirmingCompanies from './icons/menus/DanhSach.png';
// import ShowCompanies from './icons/menus/DanhSach.png';
import UnconfirmedCompanies from './icons/menus/DanhSach.png';
import ExpiringCompanies from './icons/menus/DanhSach.png';
import ExtensionCompanies from './icons/menus/DanhSach.png';
import LockingCompanies from './icons/menus/DanhSach.png';
import ConfirmedCompanies1 from './icons/menus/DanhSach.png';
import RequestExtendCompanies from './icons/menus/DanhSach.png';
import RequestUnlockCompanies from './icons/menus/DanhSach.png';

import Users from './icons/menus/NguoiDung.png';
import PlantingTypes from './icons/menus/LoaiVungTrong.png';
import Fields from './icons/menus/NganhNghe.png';
import PlantingZones from './icons/menus/VungTrong.png';
import MaterialGroups from './icons/menus/LoaiSanPham.png';
import ProductsGroups from './icons/menus/NhomSanPham.png';
import Units from './icons/menus/DonViTinh.png';
import Configs from './icons/menus/CaiDatHeThong.png';
import ProductTrace from './icons/menus/NhatKySanPham.png';
import Products from './icons/menus/SanPham.png';
import VerifiedCompany from './icons/menus/donvixacthuc.jpg';
import ProductVerified from './icons/menus/xacthucsanpham.jpg';
import SupplierVerified from './icons/menus/xacthucnhacungcap.jpg';
import ManufactVerified from './icons/menus/xacthucnhacungcap.jpg';
import TransportVerified from './icons/menus/xacthucnhacungcap.jpg';
import CompanyVerify from './icons/menus/xacthucnhacungcap.jpg';
import Verified from './icons/menus/xacthuc.jpg'; 
import Printer from './icons/menus/donviintem.jpg';
import Location from './icons/common/location.svg'; 
import StampRequestsUsed from './icons/menus/Tem.png';

import ReportZoningProduct from './icons/menus/Report1.png';
import ReportZoningCompany from './icons/menus/Report1.png';
import ReportZoningPlantingZone from './icons/menus/Report1.png';
import ReportQuantityProduct from './icons/menus/Report1.png';
import ReportQuantityProductByZone from './icons/menus/Report1.png';
import ReportProductByProvice from './icons/menus/Report1.png';
import ReportListCompany from './icons/menus/Report1.png';



const ICON_COMMONS = {
    Location
}

const ICON_MENUS = {
    Roles,
    Permissions,
    Zones,
    RoleZones,
    Logs,
    Prices,
    ConfirmedCompanies,
    ConfirmingCompanies,
    FreshCompanies,
    ExtensionCompanies,
    ExpiringCompanies,
    RequestExtendCompanies,
    RequestUnlockCompanies,
    LockingCompanies,
    ConfirmedCompanies1,
    // ShowCompanies,
    UnconfirmedCompanies,
    System,
    Report,
    IndexManagement,
    CompanyManagement,
    StampSeries,
    Financial,
    Website,
    Fields,
    Report1,
    Report2,
    Report3,
    Report4,
    News,
    Menus,
    Banner,
    Blogs,
    WebConfig,
    StampFees,
    RegisteredFees,
    StampRequests,
    StampDetails,
    Informations,
    Access,
    PlantingZones,
    MaterialGroups,
    Units,
    PlantingTypes,
    Configs,
    Users,
    ProductsGroups,
    Report8,
    Report9,
    ProductTrace,
    Products,
    VerifiedCompany,
    ProductVerified,
    SupplierVerified,
    ManufactVerified,
    TransportVerified,
    CompanyVerify,
    Verified,
    Printer,
    StampRequestsUsed,
    ReportMananger,
    ReportView,
    ReportZoningProduct,
    ReportZoningCompany,
    ReportZoningPlantingZone,
    ReportQuantityProduct,
    ReportQuantityProductByZone,
    ReportProductByProvice,
    ReportListCompany
}

export {
    ICON_MENUS,
    ICON_COMMONS
}