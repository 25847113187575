import {
    PARTNER_LIST_SUCCESS_TYPE,
    PARTNER_LIST_FAIL_TYPE,
    PARTNER_GET_SUCCESS_TYPE,
    PARTNER_GET_FAIL_TYPE,
    PARTNER_CREATE_SUCCESS_TYPE,
    PARTNER_CREATE_FAIL_TYPE,
    PARTNER_UPDATE_SUCCESS_TYPE,
    PARTNER_UPDATE_FAIL_TYPE,
    PARTNER_DELETE_SUCCESS_TYPE,
    PARTNER_DELETE_FAIL_TYPE,

    PARTNER_LIST_VERIFY_SUCCESS_TYPE,
    PARTNER_LIST_VERIFY_FAIL_TYPE,

    PARTNER_LIST_MANIFEST_VERIFY_SUCCESS_TYPE,
    PARTNER_LIST_MANIFEST_VERIFY_FAIL_TYPE,

    PARTNER_LIST_TRANSPORTS_VERIFY_SUCCESS_TYPE,
    PARTNER_LIST_TRANSPORTS_VERIFY_FAIL_TYPE,

    PARTNER_VERIFY_IMAGE_SUCCESS_TYPE,
    PARTNER_VERIFY_IMAGE_FAIL_TYPE,

    PARTNER_DELETE_UPLOAD_VERIFY_SUCCESS_TYPE,
    PARTNER_DELETE_UPLOAD_VERIFY_FAIL_TYPE,

    PARTNER_UPLOAD_VERIFY_SUCCESS_TYPE,
    PARTNER_UPLOAD_VERIFY_FAIL_TYPE

} from "../services/Common";

const initialState = { data: [], isLoading: false, status: null, message: '' };

export const partnerReducer = (state, action) => {
    state = state || initialState;

    if (action.type === PARTNER_LIST_SUCCESS_TYPE) {
        return {
            data: action.data,
            isLoading: action.isLoading,
            status: action.status,
            message: action.message,
        };
    }

    if (action.type === PARTNER_LIST_FAIL_TYPE) {
        return {
            data: action.data,
            isLoading: action.isLoading,
            status: action.status,
            message: action.message,
        };
    }

    if (action.type === PARTNER_GET_SUCCESS_TYPE) {
        return {
            data: action.data,
            isLoading: action.isLoading,
            status: action.status,
            message: action.message,
        };
    }

    if (action.type === PARTNER_GET_FAIL_TYPE) {
        return {
            data: action.data,
            isLoading: action.isLoading,
            status: action.status,
            message: action.message,
        };
    }

    if (action.type === PARTNER_GET_FAIL_TYPE) {
        return {
            data: action.data,
            isLoading: action.isLoading,
            status: action.status,
            message: action.message,
        };
    }

    if (action.type === PARTNER_GET_FAIL_TYPE) {
        return {
            data: action.data,
            isLoading: action.isLoading,
            status: action.status,
            message: action.message,
        };
    }

    if (action.type === PARTNER_CREATE_SUCCESS_TYPE) {
        return {
            data: action.data,
            isLoading: action.isLoading,
            status: action.status,
            message: action.message,
        };
    }

    if (action.type === PARTNER_CREATE_FAIL_TYPE) {
        return {
            data: action.data,
            isLoading: action.isLoading,
            status: action.status,
            message: action.message,
        };
    }

    if (action.type === PARTNER_UPDATE_SUCCESS_TYPE) {
        return {
            data: action.data,
            isLoading: action.isLoading,
            status: action.status,
            message: action.message,
        };
    }

    if (action.type === PARTNER_UPDATE_FAIL_TYPE) {
        return {
            data: action.data,
            isLoading: action.isLoading,
            status: action.status,
            message: action.message,
        };
    }

    if (action.type === PARTNER_DELETE_SUCCESS_TYPE) {
        return {
            data: action.data,
            isLoading: action.isLoading,
            status: action.status,
            message: action.message,
        };
    }

    if (action.type === PARTNER_DELETE_FAIL_TYPE) {
        return {
            data: action.data,
            isLoading: action.isLoading,
            status: action.status,
            message: action.message,
        };
    }

    if (action.type === PARTNER_LIST_VERIFY_SUCCESS_TYPE) {
        return {
            data: action.data,
            isLoading: action.isLoading,
            status: action.status,
            message: action.message,
        };
    }

    if (action.type === PARTNER_LIST_VERIFY_FAIL_TYPE) {
        return {
            data: action.data,
            isLoading: action.isLoading,
            status: action.status,
            message: action.message,
        };
    }

    if (action.type === PARTNER_LIST_MANIFEST_VERIFY_SUCCESS_TYPE) {
        return {
            data: action.data,
            isLoading: action.isLoading,
            status: action.status,
            message: action.message,
        };
    }

    if (action.type === PARTNER_LIST_MANIFEST_VERIFY_FAIL_TYPE) {
        return {
            data: action.data,
            isLoading: action.isLoading,
            status: action.status,
            message: action.message,
        };
    }

    if (action.type === PARTNER_LIST_TRANSPORTS_VERIFY_SUCCESS_TYPE) {
        return {
            data: action.data,
            isLoading: action.isLoading,
            status: action.status,
            message: action.message,
        };
    }

    if (action.type === PARTNER_LIST_TRANSPORTS_VERIFY_FAIL_TYPE) {
        return {
            data: action.data,
            isLoading: action.isLoading,
            status: action.status,
            message: action.message,
        };
    }

    if (action.type === PARTNER_VERIFY_IMAGE_SUCCESS_TYPE) {
        return {
            data: action.data,
            isLoading: action.isLoading,
            status: action.status,
            message: action.message,
        };
    }

    if (action.type === PARTNER_VERIFY_IMAGE_FAIL_TYPE) {
        return {
            data: action.data,
            isLoading: action.isLoading,
            status: action.status,
            message: action.message,
        };
    }

    if (action.type === PARTNER_DELETE_UPLOAD_VERIFY_SUCCESS_TYPE) {
        return {
            data: action.data,
            isLoading: action.isLoading,
            status: action.status,
            message: action.message,
        };
    }

    if (action.type === PARTNER_DELETE_UPLOAD_VERIFY_FAIL_TYPE) {
        return {
            data: action.data,
            isLoading: action.isLoading,
            status: action.status,
            message: action.message,
        };
    }

    if (action.type === PARTNER_UPLOAD_VERIFY_SUCCESS_TYPE) {
        return {
            data: action.data,
            isLoading: action.isLoading,
            status: action.status,
            message: action.message,
        };
    }

    if (action.type === PARTNER_UPLOAD_VERIFY_FAIL_TYPE) {
        return {
            data: action.data,
            isLoading: action.isLoading,
            status: action.status,
            message: action.message,
        };
    }


    return state;
};